import React from "react";
import { Grid } from "@material-ui/core";
import classes from "./App.module.css";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import EmailIcon from "@material-ui/icons/Email";
import { useTheme, useMediaQuery } from "@material-ui/core";

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  if (isMobile) document.body.style.background = "#48aedf";

  return (
    <div
      style={{
        height: "90%",
        width: "90%",
        margin: "auto",
        textAlign: "center",
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={1} />
        <Grid item md={1} xs={12}>
          <div className={classes.leftArrow} />
        </Grid>
        <Grid item md={2} xs={12}>
          <img src={require(`./images/${isMobile ? 'bitbird_small' : 'bitbird'}.png`)} />
        </Grid>
        <Grid item md xs={12} style={{ paddingTop: 50 }}>
          <h1>Meisam Malekzadeh</h1>
          <h3>Software Engineer</h3>
          <Grid container style={{ paddingTop: 50 }}>
            <Grid item md={4} xs={0} />
            <Grid item md={1} xs={3}>
              <a href="https://github.com/myBitBird/" target="_blank">
                <GitHubIcon fontSize="large" style={{ color: "white" }} />
              </a>
            </Grid>
            <Grid item md={1} xs={3}>
              <a
                href="https://www.linkedin.com/in/meisam-malekzadeh"
                target="_blank"
              >
                <LinkedInIcon
                  fontSize="large"
                  style={{ color: "white" }}
                  fontSize="large"
                />
              </a>
            </Grid>

            <Grid item md={1} xs={3}>
              <a href="https://virgool.io/@meisammalekzadeh" target="_blank">
                <LocalLibraryIcon
                  fontSize="large"
                  style={{ color: "white" }}
                  fontSize="large"
                />
              </a>
            </Grid>
            <Grid item md={1} xs={3}>
              <a href="mailto:meisammalekzadeh@gmail.com">
                <EmailIcon
                  fontSize="large"
                  style={{ color: "white" }}
                  fontSize="large"
                />
              </a>
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item md={4} xs={false} />
          </Grid>
        </Grid>

        <Grid
          item
          md={1}
          xs={12}
          style={{ display: "flex", alignItems: "flex-end" }}
        >
          <div className={classes.rightArrow} />
        </Grid>
        <Grid item md={1} />
      </Grid>
    </div>
  );
}

export default App;
